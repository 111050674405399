
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































































































































































































































































































































































































































.my-dashboard.commune {
  .page-header {
    display: flex;
    flex-direction: column;

    @include mq(m) {
      flex-direction: row;
      align-items: flex-start;
    }

    &__title {
      h1.main-title {
        display: block;

        @include mq(m) {
          display: inline-block;
        }
      }

      span.subtitle {
        display: inline-block;
        margin-left: 0;
        font-size: 1.4rem;
        @include mq(m) {
          margin-left: 1.5rem;
        }
      }
    }

    &__actions {
      margin-left: auto;
      flex: 0 0 auto;
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media screen and (max-width: 767px) {
        width: 100%;
        align-items: stretch;
        margin-top: 30px;

        a {
          width: 100%;
        }
      }
    }
  }

  .row--mobile {
    @include mq($from: l) {
      display: none;
    }
  }

  .row--desktop {
    @include mq($until: l) {
      display: none;
    }
  }

  article {
    > * + * {
      margin-top: unset;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    width: calc(100% / 3 - 8rem / 3);
    min-height: 35rem;
    box-shadow: 0 1rem 3rem rgba($c-black, 0.1);
    border-radius: 2rem;
    margin-right: 4rem;
    background-color: $c-white;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @include mq($until: l) {
      &:nth-child(3n) {
        margin-right: 2rem;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &__header {
      &__title {
        font-weight: 600;
        font-size: 2rem;

        @include mq($until: l) {
          font-size: 1.6rem;
          word-break: break-word;
        }
      }
    }

    &--mobile {
      width: calc(100% / 2 - 2rem / 2);
      min-height: 16.4rem;
      padding: 1.5rem;
      text-decoration: none;
      margin-right: 2rem;

      .block__header {
        display: flex;
        flex-flow: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: unset;
        font-size: 1.6rem;

        &__img {
          margin-bottom: 1rem;
        }
      }
    }

    &--desktop {
      .block__header {
        display: flex;
        margin-bottom: 2.4rem;
        align-items: center;

        &__img {
          flex: 0 0 auto;
          margin-right: 1.5rem;
          display: flex;
          height: 100%;

          img {
            @include mq($until: xl) {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }

    &__body {
      font-size: 1.6rem;
      line-height: 2.4rem;

      p + p {
        margin-top: 1rem;
      }

      &__header {
        display: inline-block;
        font-size: 1.6rem;
        color: $c-yellow;
        margin-bottom: 1.8rem;
        text-transform: uppercase;
        @include mq($until: xl) {
          font-size: 0.8em;
        }
      }

      .badge-rounded {
        padding-right: 0.4rem;
        font-weight: bold;
        color: #e84e0f;
        background: white;
      }
    }

    &__footer {
      margin-top: auto;

      &__btn {
        text-transform: uppercase;
      }
    }
  }

  div.alert-message {
    background-color: $c-bg-error;
    padding: 25px;
    margin-bottom: 30px;
    color: $c-red;
    border-radius: 20px;

    ::v-deep {
      .close-link {
        float: right;
        cursor: pointer;
      }

      svg {
        fill: $c-red;
      }
    }
  }
}
